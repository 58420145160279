export const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);
export const isObject = (value: unknown): value is Record<string, unknown> =>
    value !== null && typeof value === 'object';
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isUndefined = (value: unknown): value is undefined => value === undefined;
export const isNull = (value: unknown): value is null => value === null;
export const isInt = (value: unknown): value is number => Number.isInteger(value);
export const possibleToBeNumber = (value: unknown): value is number =>
    isString(value) && /^[-+]?[0-9]*\.?[0-9]+$/.test(value);

export const isEmail = (value: string | null): boolean => /\S+@\S+\.\S+/.test(String(value));
export const nonEmptyString = (s: unknown): boolean => isString(s) && s.length > 0;
