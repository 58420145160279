import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { BusEvents, subscribeEvent } from '../utils/bus';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        expandedAccordionSummary: {
            '&.MuiAccordionSummary-root': {
                borderBottom: '1px solid rgba(0,0,0,.12)',
            },
        },
    })
);

const useDetailsStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: 0,
        },
    })
);

type RenderProps = { open: boolean };
type StateAccordionProps = {
    title: string;
    render: (renderProps: RenderProps) => ReactNode;
    withoutPadding?: boolean;
    openByDefault?: boolean;
    busEventToExpand?: keyof BusEvents | null;
};

export const StateAccordion: FunctionComponent<StateAccordionProps> = ({
    title,
    render,
    withoutPadding = false,
    openByDefault = false,
    busEventToExpand= null,
}) => {
    const classes = useStyles();
    const detailsClasses = useDetailsStyles();
    const [open, setOpen] = useState(openByDefault);

    const containerRef = useRef<HTMLElement>();

    useEffect(() => {
        if (busEventToExpand === null) return () => {};
        return subscribeEvent(busEventToExpand, () => {
            setOpen(true);
            if (containerRef.current) {
                containerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        })
    }, [busEventToExpand])

    return (
        <Accordion
            ref={containerRef}
            expanded={open}
            defaultExpanded={openByDefault}
            onChange={(_, state) => setOpen(state)}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                classes={{ expanded: classes.expandedAccordionSummary }}
            >
                <Typography variant="h3" className={classes.heading}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails classes={withoutPadding ? detailsClasses : {}}>
                {render({ open })}
            </AccordionDetails>
        </Accordion>
    );
};
