import React, { FunctionComponent, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import {
    AppBar,
    IconButton,
    MenuItem,
    Select,
    Toolbar,
    Typography,
    Tooltip,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { ExitToApp, Menu } from '@material-ui/icons';

import { toNullable } from 'fp-ts/es6/Option';
import { findFirst } from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/function';

import { useEvent, useStore } from 'effector-react';
import { fetchLimitSettings } from '../effector/limits';
import { clearSession, SystemContractsStore, refreshToken } from '../effector/system';
import { CurrentContractStore, CurrentParamsStore, fetchDashboard, fetchContractParams } from '../effector/dashboard';

import logo from '../assets/logo.svg';

import { FeipTypograf } from '../utils/typograf';

// NOTE: @knt WTF?
import { DrawerNavigation } from './DrawerNavigation';
import { DashboardStatus } from './DashboardStatus';
import { LogoutModal } from './LogoutModal';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            backgroundColor: 'white',
            boxShadow:
                '0 2px 4px -1px rgba(156, 156, 156, 0.2), 0 4px 5px 0 rgba(202, 202, 202, 0.14), 0 1px 10px 0 rgba(120, 120, 120, 0.12)',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        rightSection: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 96,

            '@media (max-width: 350px)': {
                marginLeft: 0,
            },
        },
        formSelect: {
            padding: '19px 26px 4px 12px',
        },
        contractSelectionContainer: {
            margin: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            marginLeft: 48,
            marginRight: 'auto',
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                marginLeft: 24,
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: 'auto',
                marginRight: 0,
            },
        },
        contractSelectionLabel: {
            fontSize: 10,
            fontWeight: 400,
            position: 'absolute',
            left: 21,
            top: 12,
            zIndex: 1,
        },
        container: {
            width: 1240,
            margin: '0 auto',
            display: 'flex',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            alignItems: 'center',
        },
        logo: {
            height: 49,

            [theme.breakpoints.down('sm')]: {
                height: 40,
            },

            '@media (max-width: 350px)': {
                height: 32,
            },
        },
        topbar: {
            [theme.breakpoints.down('sm')]: {
                height: 40,
            },
        },
        navButton: {
            '@media (min-width: 721px)': {
                display: 'none',
            },
        },
        status: {
            padding: 12,
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        fullName: {
            borderLeft: '1px solid #eaeaea',
            borderRight: '1px solid #eaeaea',
            margin: 0,
            paddingLeft: 12,
            paddingRight: 12,
            [theme.breakpoints.down('lg')]: {
                borderLeft: 'none',
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: 8,
            },
            '@media (max-width: 720px)': {
                display: 'none',
            },
        },
        logout: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        tooltip: {
            fontSize: 15,
        },
    })
);

export const Header: FunctionComponent = () => {
    const classes = useStyles();

    const logout = useEvent(clearSession);
    const handleLogout = useCallback(() => logout(), [logout]);
    const { enqueueSnackbar } = useSnackbar();

    const store = useStore(CurrentParamsStore);
    const fetching = useStore(fetchDashboard.pending);
    const contracts = useStore(SystemContractsStore);
    const currentContract = useStore(CurrentContractStore);

    const currentCID = currentContract?.id || '';
    const maxHeaderNameAmount = 32;

    const [drawerIsOpen, setDrawerOpen] = useState(false);
    const [switchLoading, setSwitchLoading] = useState(false);
    const [logoutModalIsOpen, setLogoutModalOpen] = useState(false);

    const handleCIDChange: SelectInputProps['onChange'] = useCallback(
        (evt) => {
            // evt.persist();
            const cid = evt.target.value;
            if (cid === currentCID) return;

            setSwitchLoading(true);
            const stack: Promise<unknown>[] = [];
            const payload = pipe(
                contracts,
                findFirst((c) => c.id === cid),
                toNullable
            );
            if (payload) {
                stack.push(fetchDashboard(payload));
                stack.push(fetchContractParams(payload));
                stack.push(fetchLimitSettings(payload));
                stack.push(refreshToken(payload));
            }
            if (stack.length)
                Promise.all(stack)
                    .catch((e) => {
                        console.error(e);
                        enqueueSnackbar('Ошибка смены договора');
                    })
                    .finally(() => setSwitchLoading(false));
        },
        [currentCID, contracts, enqueueSnackbar]
    );

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static">
                <Toolbar classes={{ root: classes.topbar }}>
                    <div className={classes.container}>
                        <img className={classes.logo} src={logo} alt="" />
                        <Typography variant="h6" />
                        <div className={classes.rightSection}>
                            <div className={classes.contractSelectionContainer}>
                                <span className={classes.contractSelectionLabel}>Договор</span>
                                <Select
                                    className={classes.formControl}
                                    classes={{ root: classes.formSelect }}
                                    onChange={handleCIDChange}
                                    disabled={fetching || switchLoading}
                                    value={currentCID}
                                    variant="outlined"
                                >
                                    {contracts.map((contract) => (
                                        <MenuItem key={contract.id} value={contract.id}>
                                            {contract.title ?? contract.id}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <DashboardStatus className={classes.status} />

                            {/* Mobile navigation */}
                            <IconButton
                                className={classes.navButton}
                                aria-label="Навигация"
                                onClick={() => setDrawerOpen(true)}
                            >
                                <Menu />
                            </IconButton>

                            {/* Fullname */}
                            {store.fullName &&
                                (store.fullName.length > maxHeaderNameAmount ? (
                                    <Tooltip
                                        title={FeipTypograf.execute(store.fullName)}
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <p className={classes.fullName}>
                                            {FeipTypograf.execute(store.fullName.slice(0, maxHeaderNameAmount).trim())}
                                            ...
                                        </p>
                                    </Tooltip>
                                ) : (
                                    <p className={classes.fullName}>{FeipTypograf.execute(store.fullName)}</p>
                                ))}

                            {/* Logout button */}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setLogoutModalOpen(true)}
                                color="inherit"
                                className={classes.logout}
                            >
                                <ExitToApp />
                            </IconButton>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            <DrawerNavigation open={drawerIsOpen} setOpen={setDrawerOpen} />
            <LogoutModal open={logoutModalIsOpen} setOpen={setLogoutModalOpen} handleLogout={handleLogout} />
        </div>
    );
};
