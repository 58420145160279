import { createDomain } from 'effector';

import { insertAt, lookup } from 'fp-ts/es6/Record';
import { Lazy, pipe } from 'fp-ts/es6/function';
import { reduce } from 'fp-ts/es6/Array';
import * as O from 'fp-ts/es6/Option';
import { Lens } from 'monocle-ts';
import moment from 'moment';

import { apiContractDetails, apiContractParams } from '../api';
import { Protocol } from '../api/protocol';
import { clearSession } from './system';

import { ContactEmail, ContactPhone, ContractEntity } from '../types/contract';
import { ContractIdentity, ContractTariff } from '../types/tariff';
import { ApiTimestampIso } from '../utils/isomorph';

export const DashboardDomain = createDomain();

type FullNameType = string;
type BirthdayType = DateTime.Timestamp;
type PhonesType = ContactPhone[];
type EmailsType = ContactEmail[];
export type DashboardState = {
    currentContract: ContractEntity | null;
    originalContract: ContractEntity | null;
    list: Record<string, Protocol.ContractCommonResponse>;
    params: {
        fullName: FullNameType;
        birthday: BirthdayType;
        contactEmails: EmailsType;
        contactPhones: PhonesType;
        fizAddress: string;
        postAddress: string;
        urAddress: string;
    };
};

const fullNameLens = Lens.fromPath<DashboardState>()(['params', 'fullName']);
const birthdayLens = Lens.fromPath<DashboardState>()(['params', 'birthday']);
const contactPhonesLens = Lens.fromPath<DashboardState>()(['params', 'contactPhones']);
const contactEmailsLens = Lens.fromPath<DashboardState>()(['params', 'contactEmails']);

export const updateFullName = DashboardDomain.event<FullNameType>();
export const updateBirthday = DashboardDomain.event<BirthdayType>();
export const updateContactPhones = DashboardDomain.event<PhonesType>();
export const updateContactEmails = DashboardDomain.event<EmailsType>();
export const clearDashboardCache = DashboardDomain.event();

export const fetchDashboard = DashboardDomain.effect<Protocol.ContractCommonRequest, Protocol.ContractCommonResponse>();
export const fetchContractParams = DashboardDomain.effect<Protocol.ContractParamsRequest, Protocol.ContractParamsResponse>();

fetchDashboard.use(apiContractDetails);
fetchContractParams.use(apiContractParams);

const initialState: DashboardState = {
    currentContract: null,
    originalContract: null,
    list: {},
    params: {
        fullName: '',
        birthday: ApiTimestampIso.from(moment()),
        contactEmails: [],
        contactPhones: [],
        fizAddress: '',
        postAddress: '',
        urAddress: '',
    },
};

export const DashboardStore = DashboardDomain.createStore(initialState)
    .on(fetchContractParams.done, (state, payload) => ({
        ...state,
        params: {
            fullName: payload.result.fullName,
            contactPhones: payload.result.contactPhones,
            contactEmails: payload.result.contactEmails,
            fizAddress: payload.result.fizAddress,
            postAddress: payload.result.postAddress,
            birthday: payload.result.birthday,
            urAddress: payload.result.urAddress,
        },
    }))
    .on(fetchDashboard.done, (state, payload) => ({
        ...state,
        currentContract: payload.params,
        originalContract: state.originalContract ?? payload.params,
        list: insertAt(payload.params.id.toString(), payload.result)(state.list),
    }))
    .on(updateFullName, (state, value) => fullNameLens.set(value)(state))
    .on(updateBirthday, (state, value) => birthdayLens.set(value)(state))
    .on(updateContactPhones, (state, value) => contactPhonesLens.set(value)(state))
    .on(updateContactEmails, (state, value) => contactEmailsLens.set(value)(state))
    .reset(clearDashboardCache, clearSession);

DashboardDomain.createEvent();

export const OriginalContractStore = DashboardStore.map((state) => state.originalContract);
export const CurrentContractStore = DashboardStore.map((state) => state.currentContract);
export const CurrentBilling = CurrentContractStore.map((state) => (state ? state.billing : null));

export const CurrentParamsStore = DashboardStore.map(({ params }) => params);
export const CurrentDashboardStore = DashboardStore.map((state) =>
    pipe(
        O.fromNullable(state.currentContract),
        O.chain((contract) => lookup(contract.id.toString())(state.list)),
        O.toNullable
    )
);

type CurrentTariffState = Record<ContractIdentity, ContractTariff | null>;
const tariffConst: Lazy<CurrentTariffState> = () => ({ current: null, planned: null });

export const CurrentTariffStore = CurrentDashboardStore.map((state) =>
    pipe(
        O.fromNullable(state?.currentTariffs),
        O.map(reduce(tariffConst(), (acc, item) => ({ ...acc, [item.kind]: item }))),
        O.getOrElse(tariffConst)
    )
);
