import React, { FunctionComponent, useState } from 'react';
import { useSnackbar } from 'notistack';

import { saveAs } from 'file-saver';
import MomentUtils from '@date-io/moment';
import moment, { Moment } from 'moment';

import { createStyles, FormControl, IconButton, TablePagination, Theme, Tooltip } from '@material-ui/core';
import { GetApp as GetAppIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { makeStyles } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { CurrentContractStore, CurrentDashboardStore } from '../effector/dashboard';

import { formatPrice, foldApiTimestampToDate } from '../utils/view';

import { apiPayonlineGetPDF } from '../api';
import { Protocol } from '../api/protocol';
import { ApiError } from '../api/client/error';

import { PaymentHistoryItem } from '../types/paymentHistory';
import { BalanceType } from '../types/balance';

import { Table, TableColumns } from '../containers/Table';
import { AccordionLoader } from './AccordionLoader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'block',
            width: '100%',
        },
        filters: {
            margin: theme.spacing(2),
        },
    })
);

const columns: TableColumns<PaymentHistoryItem> = [
    {
        id: 'transaction_id',
        align: 'left',
        label: '№ транзакции',
        minWidth: 100,
        isNoWrap: true,
    },
    {
        id: 'datePay',
        label: 'Дата',
        format: foldApiTimestampToDate,
        minWidth: 84,
    },
    {
        id: 'sum',
        label: 'Сумма, ₽',
        format: (value) => formatPrice(value, 2, ' '),
        isNoWrap: true,
        align: 'right',
        tooltip: {
            valueColumnID: 'status',
            map: (status) => (status === 'Одобренная' || status === undefined ? BalanceType.Income : BalanceType.Info),
        },
    },
    {
        id: 'status',
        label: 'Статус',
        isNoWrap: true,
    },
];

type Props = {
    loading: boolean;
    fetched: boolean;
    date: Moment;
    items: PaymentHistoryItem[];
    pagination: Pagination;
    fetch: (newDate?: Moment, customData?: Record<string, any>) => void;
    hiddenColumns?: Array<keyof PaymentHistoryItem>;
};
export const PaymentHistoryContent: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const currentContract = useStore(CurrentContractStore);
    const currentDashboard = useStore(CurrentDashboardStore);
    const [loadingPDF, setLoadingPDF] = useState(false);

    const { loading, fetched, date, items, pagination, fetch, hiddenColumns } = props;
    const filteredColumns =
        hiddenColumns !== undefined ? columns.filter((column) => !hiddenColumns.includes(column.id)) : columns;

    const fetchPDF = async (transactionId: number): Promise<Protocol.PayOnlineGetPDFResponse | void> => {
        if (!currentContract || loadingPDF) throw new ApiError('Ошибка обработки. Попробуйте позже');

        setLoadingPDF(true);

        return apiPayonlineGetPDF({
            ...currentContract,
            transaction_id: transactionId,
        })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => setLoadingPDF(false));
    };

    const downloadPDF = (data: PaymentHistoryItem): void => {
        if (!currentDashboard) return;

        fetchPDF(data.transaction_id)
            .then((file) => {
                if (file) {
                    const filename = `${currentDashboard.title}_${data.transaction_id}_${foldApiTimestampToDate(
                        data.datePay
                    )}.pdf`;
                    saveAs(file, filename);
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const openPDF = (data: PaymentHistoryItem): void => {
        fetchPDF(data.transaction_id)
            .then((file) => {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const keyPDF = 'pdf_download';
    if (hiddenColumns !== undefined && !hiddenColumns.includes(keyPDF)) {
        filteredColumns.push({
            id: keyPDF,
            label: 'Квитанция',
            callback: (data, column) => {
                // eslint-disable-next-line no-param-reassign
                column.format = () => (
                    <>
                        <Tooltip title="Скачать квитанцию">
                            <IconButton onClick={() => downloadPDF(data)} disabled={loadingPDF}>
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Открыть квитанцию в новой вкладке">
                            <IconButton onClick={() => openPDF(data)} disabled={loadingPDF}>
                                <OpenInNewIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            isNoWrap: true,
        });
    }

    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                    <FormControl>
                        <DatePicker
                            value={date}
                            label="Дата"
                            views={['month', 'year']}
                            maxDate={moment().endOf('day')}
                            onChange={(newDate: MaterialUiPickersDate) => {
                                if (!newDate) return;
                                fetch(newDate);
                            }}
                            cancelLabel="Отмена"
                            okLabel="Сохранить"
                            minDateMessage="Дата не должна быть раньше 2000 года"
                            maxDateMessage="Дата не должна превышать текущую дату"
                            disableFuture
                        />
                    </FormControl>
                </MuiPickersUtilsProvider>
            </div>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
                <AccordionLoader />
            ) : items.length === 0 && fetched ? (
                <AccordionLoader text="Не найдено данных за выбранный период" />
            ) : (
                <>
                    <Table data={items} columns={filteredColumns} keyProp={['datePay', 'transaction_id']} />
                    <TablePagination
                        component="div"
                        rowsPerPage={pagination.pageSize}
                        count={pagination.pageTotalRecordsCount}
                        page={pagination.pageNumber - 1}
                        labelRowsPerPage="Записей на странице"
                        onChangePage={(event, value) => {
                            fetch(date, {
                                pageNumber: value + 1,
                            });
                        }}
                        onChangeRowsPerPage={(event) => {
                            fetch(date, {
                                pageSize: Number(event.target.value),
                            });
                        }}
                    />
                </>
            )}
        </div>
    );
};
