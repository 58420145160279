import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useStore } from 'effector-react';
import { CurrentContractStore, CurrentParamsStore } from '../effector/dashboard';
import { BalancePaymentMethod, BalancePaymentMethodType, PaymentRequest } from '../types/balance';

import { apiPayonlineDoPay, apiSberbankDoPay } from '../api';

import { DEFAULT_PAYMENT_PRICE, MAX_PAYMENT_PRICE, MIN_PAYMENT_PRICE } from '../utils/constants';
import { buildPaymentRedirectUrl, firstContact } from '../utils/strings';
import { formatErrorText } from '../utils/view';
import { isEmail, isInt } from '../utils/guards';
import { redirect } from '../utils/query';

import { ButtonWithLoading } from './ButtonWithLoading';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: 12,
        },
        textInput: {
            marginBottom: 12,
        },
        button: {
            width: '100%',
        },
        radios: {
            marginBottom: 8,
        },
        radioLabelWithDescription: {
            alignItems: 'flex-start',
        },
        radioInput: {
            paddingTop: 1,
        },
        radioLabel: {
            color: theme.palette.text.secondary,
            fontSize: 12,
        },
    })
);

const validationSchema = {
    email: {
        required: true,
        validate: (value: string) => isEmail(value) || 'E-mail имеет неверный формат',
    },
    summa: {
        validate: (value: string) =>
            (Number(value) >= MIN_PAYMENT_PRICE && Number(value) <= MAX_PAYMENT_PRICE) ||
            `Сумма должна быть в промежутке от ${MIN_PAYMENT_PRICE} до ${MAX_PAYMENT_PRICE}`,
        required: true,
    },
};

type FormInputs = PaymentRequest & { paymentMethod: BalancePaymentMethodType };

export const BalanceReplenishForm: FunctionComponent = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const currentContract = useStore(CurrentContractStore);
    const currentParams = useStore(CurrentParamsStore);
    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, errors, setValue, clearError } = useForm<FormInputs>({
        reValidateMode: 'onSubmit',
        defaultValues: {
            summa: DEFAULT_PAYMENT_PRICE,
            email: currentParams ? firstContact(currentParams.contactEmails, 'email') || '' : '',
        },
    });

    const { enableSberbank = false, enablePayOnline = false } = currentContract || {};

    const handleAmountChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const { value } = event.target;
        if (isInt(value)) {
            setValue('summa', Number(value));
        }

        if (value.length > String(MAX_PAYMENT_PRICE).length) {
            setValue('summa', Number(value.slice(0, String(MAX_PAYMENT_PRICE).length)));
        }
    };

    const onSubmit = (data: FormInputs): void => {
        const { paymentMethod, ...payload } = data;

        if (Number(payload.summa) < MIN_PAYMENT_PRICE || Number(payload.summa) > MAX_PAYMENT_PRICE) {
            enqueueSnackbar(`Неверная сумма для оплаты: ${MIN_PAYMENT_PRICE}₽`, {
                variant: 'error',
            });
            return;
        }

        if (currentContract) {
            setLoading(true);

            const formattedPayload = {
                ...currentContract,
                email: payload.email,
                summa: Number(payload.summa),
            };

            switch (paymentMethod) {
                case BalancePaymentMethod.SBERBANK.toString(): {
                    apiSberbankDoPay(formattedPayload)
                        .then((response) => {
                            enqueueSnackbar('Перенаправляем на платёжную страницу Сбербанка', {
                                variant: 'success',
                            });
                            redirect(response.result);
                        })
                        .catch((e) => {
                            enqueueSnackbar(e.message, { variant: 'error' });
                            setLoading(false);
                        });
                    break;
                }
                case BalancePaymentMethod.PAYONLINE.toString(): {
                    apiPayonlineDoPay({
                        ...formattedPayload,
                        redirectURL: buildPaymentRedirectUrl(),
                    })
                        .then((response) => {
                            enqueueSnackbar('Перенаправляем на платёжную страницу PayOnline', { variant: 'success' });
                            redirect(response.result);
                        })
                        .catch((e) => {
                            enqueueSnackbar(e.message, { variant: 'error' });
                            setLoading(false);
                        });
                    break;
                }
                default: {
                    enqueueSnackbar('Неверный способ оплаты', { variant: 'error' });
                }
            }
        }
    };

    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)} onChange={() => clearError()}>
            {/* Email input */}
            <TextField
                id="balance-replenish-input-email"
                type="text"
                name="email"
                label="E-mail"
                disabled={loading}
                aria-invalid={errors.email ? 'true' : 'false'}
                helperText={formatErrorText(errors.email)}
                error={Boolean(errors.email && errors.email.message)}
                inputRef={register(validationSchema.email)}
                className={classes.textInput}
                InputProps={{
                    inputProps: {
                        inputMode: 'email',
                    },
                }}
            />

            {/* Number input */}
            <TextField
                id="balance-replenish-input-amount"
                type="number"
                name="summa"
                disabled={loading}
                aria-invalid={errors.summa ? 'true' : 'false'}
                helperText={formatErrorText(errors.summa)}
                error={Boolean(errors.summa && errors.summa.message)}
                inputRef={register(validationSchema.summa)}
                className={classes.textInput}
                onChange={handleAmountChange}
                defaultValue={DEFAULT_PAYMENT_PRICE}
                InputProps={{
                    startAdornment: <InputAdornment position="start">₽</InputAdornment>,
                    inputProps: {
                        max: MAX_PAYMENT_PRICE,
                        min: MIN_PAYMENT_PRICE,
                    },
                }}
            />

            {/* Payment type */}
            <FormControl component="fieldset" className={classes.radios}>
                <RadioGroup
                    name="paymentMethod"
                    defaultValue={enableSberbank ? BalancePaymentMethod.SBERBANK : BalancePaymentMethod.PAYONLINE}
                >
                    {enableSberbank && (
                        <FormControlLabel
                            disabled={loading}
                            value={BalancePaymentMethod.SBERBANK}
                            inputRef={register}
                            control={<Radio color="primary" />}
                            label="Оплата картой"
                        />
                    )}
                    {enablePayOnline && (
                        <FormControlLabel
                            disabled={loading}
                            value={BalancePaymentMethod.PAYONLINE}
                            classes={{
                                root: classes.radioLabelWithDescription,
                            }}
                            inputRef={register}
                            control={<Radio color="primary" classes={{ root: classes.radioInput }} />}
                            label={
                                <div>
                                    <div>Другой способ</div>
                                    <div className={classes.radioLabel}>
                                        QIWI, WebMoney, Яндекс.Деньги, Apple Pay, Google Pay
                                    </div>
                                </div>
                            }
                        />
                    )}
                </RadioGroup>
            </FormControl>

            {/* Submit button */}
            <ButtonWithLoading type="submit" loading={loading} color="primary" noMargin className={classes.button}>
                Оплатить
            </ButtonWithLoading>
        </form>
    );
};
