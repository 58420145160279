import type { FC } from 'react';
import React from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core';

import { useStore } from 'effector-react';
import { CurrentDashboardStore } from '../../effector/dashboard';

import { FeipTypograf } from '../../utils/typograf';

import { Section } from './Section';
import { SkeletonText } from './SkeletonText';

enum ServiceStatus {
    Open = 'открыт',
    Closed = 'закрыт',
    Blocked = 'заблокирован',
}

enum ServiceState {
    On = 'включен',
    Off = 'отключен',
    Deleted = 'удален',
}

const useStyles = makeStyles(() =>
    createStyles({
        list: {
            listStyle: 'none',
            margin: '24px 0 0',
            padding: 0,
        },
        itemValue: {
            color: '#202020',
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
        itemDescription: {
            color: '#404040',
            fontSize: 14,
            lineHeight: 1,
            margin: '8px 0 0',
        },
        itemInnerList: {
            '&:not(:last-child)': {
                marginBottom: 12,
            },
        },
        withStatus: {
            paddingLeft: 24,
            position: 'relative',
            '&::before': {
                backgroundColor: '#808080',
                borderRadius: '50%',
                content: '""',
                height: 14,
                left: 0,
                position: 'absolute',
                top: 4,
                width: 14,
            },
        },
        withStatus_online: {
            '&::before': {
                backgroundColor: '#21BF73',
            },
        },
        withStatus_offline: {
            '&::before': {
                backgroundColor: '#D32F2F',
            },
        },
        withStatus_blocked: {
            '&::before': {
                backgroundColor: '#baa640',
            },
        },
        itemDescription_smallGutter: {
            marginTop: 4,
        },
    })
);

type InternetServicesSectionProps = {
    isLoading: boolean;
};

export const InternetServicesSection: FC<InternetServicesSectionProps> = (props) => {
    const { isLoading } = props;

    const classes = useStyles();

    const dashboard = useStore(CurrentDashboardStore);

    return (
        <Section title="Состояние интернет-сервисов">
            {isLoading ? (
                <ul className={classes.list}>
                    <li className={classes.itemInnerList}>
                        <SkeletonText />
                    </li>
                    <li className={classes.itemInnerList}>
                        <SkeletonText />
                    </li>
                </ul>
            ) : (
                <ul className={classes.list}>
                    {dashboard?.inetServicesCurrentStatus?.map((item, index) => (
                        <li key={index} className={classes.itemInnerList}>
                            <div
                                className={clsx(
                                    classes.withStatus,
                                    item.status === ServiceStatus.Open &&
                                        item.state === ServiceState.On &&
                                        classes.withStatus_online,
                                    item.status === ServiceStatus.Open &&
                                        (item.state === ServiceState.Off || item.state === ServiceState.Deleted) &&
                                        classes.withStatus_offline,
                                    item.status === ServiceStatus.Blocked && classes.withStatus_blocked
                                )}
                            >
                                <p className={classes.itemValue}>{FeipTypograf.execute(item.title)}</p>
                                <p className={clsx(classes.itemDescription, classes.itemDescription_smallGutter)}>
                                    {FeipTypograf.execute(item.state)}, {FeipTypograf.execute(item.status)}
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </Section>
    );
};
