import type { FC } from 'react';
import React from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { useStore } from 'effector-react';
import { CurrentDashboardStore, CurrentParamsStore } from '../../effector/dashboard';

import { FeipTypograf } from '../../utils/typograf';
import { formatPhone, foldApiTimestampToDate } from '../../utils/view';
import { getStatusConfig } from '../../utils/dashboardStatus';

import { ChangeBirthdayParam } from '../change-params/ChangeBirthdayParam';
import { ChangeEmailsParam } from '../change-params/ChangeEmailsParam';
import { ChangeFullNameParam } from '../change-params/ChangeFullNameParam';
import { ChangePhonesParam } from '../change-params/ChangePhonesParam';
import { Section } from './Section';
import { SkeletonText } from './SkeletonText';
import { QRCode } from '../QRCode';

const useStyles = makeStyles((theme) =>
    createStyles({
        list: {
            listStyle: 'none',
            margin: '24px 0 0',
            padding: 0,
        },
        listItem: {
            display: 'flex',
            '&:not(:last-child)': {
                marginBottom: 12,
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
            },
        },
        listLabel: {
            color: theme.palette.text.secondary,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1.35,
            margin: 0,
            marginRight: 16,
            minWidth: 192,
        },
        listValue: {
            color: theme.palette.text.primary,
            fontSize: 16,
            lineHeight: 1.35,
            margin: 0,
        },
        listValueWithGutter: {
            marginRight: 6,
        },
        listValueWrapper: {
            alignItems: 'center',
            display: 'flex',
        },
        withStatus: {
            paddingLeft: 24,
            position: 'relative',
            '&::before': {
                backgroundColor: '#808080',
                borderRadius: '50%',
                content: '""',
                height: 14,
                left: 0,
                position: 'absolute',
                top: 4,
                width: 14,
            },
        },
        withStatus_online: {
            '&::before': {
                backgroundColor: '#21BF73',
            },
        },
        withStatus_offline: {
            '&::before': {
                backgroundColor: '#D32F2F',
            },
        },
        withStatus_blocked: {
            '&::before': {
                backgroundColor: '#baa640',
            },
        },
        qrCode: {
            display: 'flex',
            marginTop: 24,
        },
    })
);

const formatContact = (value: string, description: string): string =>
    `${value} ${description ? ` (${description})` : ''}`;

// === List item component

type ListItemProps = {
    title: string;
    isLoading: boolean;
    classes: ClassNameMap<'listItem' | 'listLabel'>;
};

const ListItem: FC<ListItemProps> = (props) => {
    const { title, isLoading, classes, children } = props;

    return (
        <li className={classes.listItem}>
            <h4 className={classes.listLabel}>{title}</h4>

            {isLoading ? <SkeletonText /> : children}
        </li>
    );
};

// === Section component ===

type ContractDetailsSectionProps = {
    isLoading: boolean;
};

export const ContractDetailsSection: FC<ContractDetailsSectionProps> = (props) => {
    const { isLoading } = props;

    const classes = useStyles();

    const store = useStore(CurrentParamsStore);
    const dashboard = useStore(CurrentDashboardStore);

    const statusConfig = dashboard !== null ? getStatusConfig(dashboard.status) : null;

    const listItemProps: Pick<ListItemProps, 'isLoading' | 'classes'> = {
        isLoading,
        classes: {
            listItem: classes.listItem,
            listLabel: classes.listLabel,
        },
    };

    return (
        <Section title="Детали договора">
            <ul className={classes.list}>
                <ListItem title="Имя клиента" {...listItemProps}>
                    <div className={classes.listValueWrapper}>
                        <p className={clsx(classes.listValue, classes.listValueWithGutter)}>
                            {FeipTypograf.execute(store.fullName ?? '—')}
                        </p>

                        <ChangeFullNameParam />
                    </div>
                </ListItem>

                <ListItem title="Телефон" {...listItemProps}>
                    <div className={classes.listValueWrapper}>
                        <p
                            className={clsx(classes.listValue, classes.listValueWithGutter)}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html:
                                    store.contactPhones.length > 0
                                        ? store.contactPhones
                                              .map((item) => formatContact(formatPhone(item.phone), item.comment))
                                              .join('<br />')
                                        : '—',
                            }}
                        />

                        <ChangePhonesParam />
                    </div>
                </ListItem>

                <ListItem title="E-mail" {...listItemProps}>
                    <div className={classes.listValueWrapper}>
                        <p
                            className={clsx(classes.listValue, classes.listValueWithGutter)}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html:
                                    store.contactEmails.length > 0
                                        ? store.contactEmails
                                              .map((item) => formatContact(item.email, item.comment))
                                              .join('<br />')
                                        : '—',
                            }}
                        />

                        <ChangeEmailsParam />
                    </div>
                </ListItem>

                <ListItem title="Дата рождения" {...listItemProps}>
                    <div className={classes.listValueWrapper}>
                        <p className={clsx(classes.listValue, classes.listValueWithGutter)}>
                            {store.birthday ? foldApiTimestampToDate(store.birthday) : 'Не указано'}
                        </p>

                        <ChangeBirthdayParam />
                    </div>
                </ListItem>

                <ListItem title="Название договора" {...listItemProps}>
                    <p className={classes.listValue}>{FeipTypograf.execute(dashboard?.title || '—')}</p>
                </ListItem>

                <ListItem title="Комментарий" {...listItemProps}>
                    <p className={classes.listValue}>{FeipTypograf.execute(dashboard?.comment || '—')}</p>
                </ListItem>

                <ListItem title="Дата открытия" {...listItemProps}>
                    <p className={classes.listValue}>
                        {dashboard?.dateFrom ? foldApiTimestampToDate(dashboard?.dateFrom) : '—'}
                    </p>
                </ListItem>

                <ListItem title="Статус" {...listItemProps}>
                    <p
                        className={clsx(
                            classes.listValue,
                            classes.withStatus,
                            statusConfig?.type === 'online' && classes.withStatus_online,
                            statusConfig?.type === 'offline' && classes.withStatus_offline,
                            statusConfig?.type === 'blocked' && classes.withStatus_blocked
                        )}
                    >
                        {statusConfig?.text ?? '—'}
                    </p>
                </ListItem>
            </ul>

            <div className={classes.qrCode}>
                <QRCode />
            </div>
        </Section>
    );
};
