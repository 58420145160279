import type { FC } from 'react';
import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        section: {
            backgroundColor: 'white',
            padding: 32,
            '&:not(:last-child)': {
                borderBottom: '1px solid #EAEAEA',
            },
            [theme.breakpoints.down('md')]: {
                padding: 20,
            },
        },
        heading: {
            color: theme.palette.text.primary,
            fontSize: 24,
            fontWeight: 500,
            lineHeight: 1,
            margin: 0,
            [theme.breakpoints.down('md')]: {
                fontSize: 20,
            },
        },
    })
);

type SectionProps = {
    title: string;
};

export const Section: FC<SectionProps> = (props) => {
    const { title, children } = props;

    const classes = useStyles();

    return (
        <section className={classes.section}>
            <h2 className={classes.heading}>{title}</h2>

            {children}
        </section>
    );
};
