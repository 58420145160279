import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconComponent } from '@material-ui/icons';
import { CircularProgress, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            border: '1px solid #eaeaea',
            borderRadius: 10,
            cursor: 'pointer',
            display: 'flex',
            fontFamily: 'inherit',
            outline: 'none',
            padding: 24,
            textAlign: 'left',
            transition: 'border-color 0.25s ease-out',
            width: '100%',
            '&:hover, &:focus': {
                borderColor: theme.palette.primary.main,
            },
            [theme.breakpoints.down('md')]: {
                padding: 16,
            },
        },
        rootDisabled: {
            opacity: 0.6,
        },
        rootLoading: {
            position: 'relative',
        },
        loading: {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            transform: 'translate(calc(50% - 20px), calc(50% - 20px))',
        },
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 12,
            [theme.breakpoints.down('md')]: {
                marginLeft: 8,
            },
        },
        icon: {
            flexShrink: 0,
        },
        title: {
            color: '#1B1918',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.25,
            margin: 0,
        },
        description: {
            color: '#A1A1A1',
            fontSize: 14,
            lineHeight: 1.25,
            margin: 0,
            marginTop: 8,
        },
    })
);

type Props = {
    onClick: Noop;
    title: string;
    description?: string;
    Icon: SvgIconComponent;
    disabled?: boolean;
    disabledText?: string;
    loading?: boolean;
    loadingText?: string;
};

export const HelpButtonCard: FunctionComponent<Props> = (props) => {
    const { onClick, title, description, Icon, loadingText, disabledText, loading = false, disabled = false } = props;

    const classes = useStyles();

    const button = (
        <button
            type="button"
            className={clsx(classes.root, disabled && classes.rootDisabled, loading && classes.rootLoading)}
            onClick={() => {
                if (!disabled) onClick();
            }}
        >
            <div className={classes.icon}>
                <Icon color="primary" />
            </div>

            <div className={classes.wrapper}>
                <h2 className={classes.title}>{title}</h2>

                {description !== undefined && <p className={classes.description}>{description}</p>}
            </div>

            {loading && (
                <div className={classes.loading}>
                    <CircularProgress color="primary" />
                </div>
            )}
        </button>
    );

    if (loading && loadingText !== undefined) return <Tooltip title={loadingText}>{button}</Tooltip>;

    if (disabled && disabledText !== undefined) return <Tooltip title={disabledText}>{button}</Tooltip>;

    return button;
};
