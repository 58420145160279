import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { constNull, pipe } from 'fp-ts/es6/function';
import { fold, fromNullable } from 'fp-ts/es6/Option';

import { useStore } from 'effector-react';
import { CurrentBilling, CurrentTariffStore } from '../effector/dashboard';

import { LOCATION_CAPTION, LOCATION_HASH, LOCATION_ROUTE } from '../utils/constants';
import { isInt, isNull } from '../utils/guards';
import { parseNumber } from '../utils/strings';
import { useQuery } from '../utils/query';

import { useConstructor } from '../hooks/constructor';

import { StateAccordion } from '../containers/StateAccordion';
import { withTitle } from '../containers/WithTitle';
import { Content } from '../containers/Content';

import { AutoReplenishment } from '../components/AutoReplenishment';
import { ContractPassword } from '../components/password-change/ContractPassword';
import { PPPOEPassword } from '../components/password-change/PPPOEPassword';
import { ContractsList } from '../components/ContractsList';
import { TariffGrid } from '../components/TariffGrid';
import { NotificationsSettings } from '../components/NotificationsSettings';

const Settings: FunctionComponent = () => {
    const query = useQuery();
    const { push } = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [openedChangeTariff, setOpenedChangeTariff] = useState(false);

    const billing = useStore(CurrentBilling);
    const tariffs = useStore(CurrentTariffStore);

    const serviceId = useMemo(() => pipe(fromNullable(query.get('service')), fold(constNull, parseNumber)), [query]);

    const location = useLocation();
    useConstructor(() => {
        if (location.hash === LOCATION_HASH.CHANGE_TARIFF) {
            setOpenedChangeTariff(true);
        }
    });

    useEffect(() => {
        if (Number.isNaN(serviceId) || (!isNull(serviceId) && !isInt(serviceId))) {
            push(LOCATION_ROUTE.SETTINGS);
            enqueueSnackbar(`Неправильный формат сервиса`, {
                variant: 'warning',
            });
        }
    }, [serviceId, push, enqueueSnackbar]);

    return (
        <Content>
            {tariffs.current && tariffs.current.tariffsForChange.length > 0 && (
                <StateAccordion
                    title="Смена тарифа"
                    openByDefault={openedChangeTariff}
                    busEventToExpand="showChangeTariffBlock"
                    render={({ open }) => <TariffGrid open={open} />}
                />
            )}

            <StateAccordion title="Смена пароля личного кабинета" render={() => <ContractPassword />} />
            {billing === 'internet' && (
                <StateAccordion
                    title="Смена пароля PPPoE"
                    openByDefault={isInt(serviceId)}
                    render={(props) => <PPPOEPassword defaultService={serviceId} {...props} />}
                />
            )}
            <StateAccordion title="Присоединенные договора" render={() => <ContractsList />} />

            <StateAccordion title="Автоплатеж (PayOnline)" render={({ open }) => <AutoReplenishment open={open} />} />

            <StateAccordion title="Push-уведомления" render={({ open }) => <NotificationsSettings />} />
        </Content>
    );
};

const Component = pipe(Settings, withTitle(LOCATION_CAPTION.SETTINGS));
export { Component as Settings };
