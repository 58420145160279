import { Moment } from 'moment';

export enum BalanceType {
    Info,
    Income,
    Expense,
}

export type PaymentSystem = 'sberbank' | 'payonline';
export type PaymentRequest = {
    summa: number;
    email: string;
    redirectURL: string;
};

export type PaymentResponse = {
    result: string;
};

export enum BalancePaymentMethod {
    SBERBANK = 'sberbank',
    PAYONLINE = 'payonline',
}
export type BalancePaymentMethodType = keyof typeof BalancePaymentMethod;

export type BalanceApiPayload = {
    yearFrom: string;
    monthFrom: string;
    yearTo: string;
    monthTo: string;
};

export type BalanceAppPayload = {
    from: Moment;
    to: Moment;
};

export type BalanceListItem = {
    dt: DateTime.Timestamp;
    title: string;
    coment: string;
    sum: number;
    balance: number;
    type: BalanceType;
};
