import React, { FunctionComponent, useState } from 'react';
import { Fade, IconButton, Modal, Typography } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStore } from 'effector-react';
import { useSnackbar } from 'notistack';

import { apiGetQRCode } from '../api';
import { CurrentContractStore } from '../effector/dashboard';
import { useStyles } from './modal/styles';
import { ButtonWithLoading } from './ButtonWithLoading';

export const QRCode: FunctionComponent = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [qrImage, setQrImage] = useState<string>();
    const currentContract = useStore(CurrentContractStore);
    const { enqueueSnackbar } = useSnackbar();

    const closeModal = (): void => setOpenModal(false);

    const getQR = (): void => {
        if (!currentContract) return;

        setLoading(true);
        const payload = { ...currentContract };
        apiGetQRCode(payload)
            .then((response) => {
                const image = URL.createObjectURL(response);
                setQrImage(image);
                setOpenModal(true);
            })
            .catch(({ message }) => {
                enqueueSnackbar(message, { variant: 'error' });
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <ButtonWithLoading type="submit" loading={loading} color="primary" onClick={getQR} noMargin>
                Показать QR-код
            </ButtonWithLoading>

            <Modal open={openModal} onClose={closeModal} aria-labelledby="Модальное окно получения QR-кода">
                <Fade in={openModal}>
                    <div className={classes.body}>
                        <Typography variant="h6" className={classes.title}>
                            QR-код
                        </Typography>

                        <p className={classes.description}>
                            Предъявив его, вы&nbsp;получите уникальные предложения от&nbsp;
                            наших партнеров, <a rel="noopener noreferrer" target="_blank" href="https://iks.ru">подробнее</a>
                        </p>

                        {qrImage ? (
                            <img alt="QR-код" src={qrImage} style={{ margin: '0 auto', display: 'block' }} />
                        ) : (
                            <p>Изображение не было загружено, перезагрузите страницу и попробуйте ещё раз</p>
                        )}

                        <IconButton aria-label="Закрыть" className={classes.close} onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
