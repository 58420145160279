import React, { FunctionComponent } from 'react';

import { Button } from '@material-ui/core';
import { Edit as EditIcon, SvgIconComponent } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.text.secondary,
            fontSize: 14,
            fontWeight: theme.typography.fontWeightMedium,
            height: 'auto',
            padding: 0,
            minWidth: 'auto',
            transition: 'background-color 0.2s ease-out, color 0.2s ease-out',
            '&:hover, &:focus': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
            },
        },
        buttonTopGutter: {
            marginTop: 8,
        },
        label: {
            marginRight: 5,
        },
        icon: {
            fontSize: 16,
        },
    })
);

type EditButtonProps = {
    label?: string;
    onClick: Noop;
    icon?: SvgIconComponent,
    classesOverride?: Partial<ReturnType<typeof useStyles>>
    withTopGutter?: boolean;
}
export const EditButton: FunctionComponent<EditButtonProps> = (props) => {
    const { label, withTopGutter = true } = props;

    const defaultClasses = useStyles();

    const iconClass = clsx(defaultClasses.icon, props.classesOverride?.icon);
    const buttonClass = clsx([
        defaultClasses.button,
        props.classesOverride?.button,
        withTopGutter && defaultClasses.buttonTopGutter,
    ]);

    const IconComponent = props.icon || EditIcon;

    return (
        <Button className={buttonClass} onClick={props.onClick} disableElevation disableFocusRipple>
            {label !== undefined && <span className={defaultClasses.label}>{label}</span>}
            <IconComponent fontSize="small" className={iconClass}/>
        </Button>
    )
}
