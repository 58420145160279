import React, { FunctionComponent, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { pipe } from 'fp-ts/es6/function';
import { useStore } from 'effector-react';
import { CurrentBilling, CurrentContractStore } from '../effector/dashboard';

import { LOCATION_CAPTION } from '../utils/constants';
import { useConstructor } from '../hooks/constructor';

import { StateAccordion } from '../containers/StateAccordion';
import { withTitle } from '../containers/WithTitle';
import { Content } from '../containers/Content';

import { PayonlineHistory } from '../components/PayonlineHistory';
import { SberbankHistory } from '../components/SberbankHistory';
import { ContractLimits } from '../components/ContractLimits';
import { OutcomingList } from '../components/OutcomingList';
import { IncomingList } from '../components/IncomingList';
import { ServiceList } from '../components/ServiceList';
import { TariffList } from '../components/TariffList';
import { PointList } from '../components/PointList';
import { Balance } from '../components/Balance';
import { Traffic } from '../components/Traffic';

const Advanced: FunctionComponent = () => {
    const billing = useStore(CurrentBilling);
    const { enableLimit = false, enableSberbank = false, enablePayOnline = false } =
        useStore(CurrentContractStore) || {};

    const location = useLocation();
    const [openBalance, setOpenBalance] = useState(false);

    useConstructor(() => {
        if (location.hash === '#balance') {
            setOpenBalance(true);
        }
    });

    return (
        <Content>
            <StateAccordion
                title="История баланса"
                render={(props) => <Balance {...props} />}
                openByDefault={openBalance}
                withoutPadding
                busEventToExpand="showBalanceBlock"
            />
            {enableLimit && (
                <StateAccordion
                    title="История лимитов"
                    withoutPadding
                    render={({ open }) => <ContractLimits open={open} />}
                />
            )}
            {billing === 'internet' && (
                <>
                    <StateAccordion title="Интернет-трафик" render={(props) => <Traffic {...props} />} withoutPadding />
                    <StateAccordion
                        title="Интернет-сервисы"
                        render={(props) => <ServiceList {...props} />}
                        withoutPadding
                    />
                </>
            )}
            {billing === 'phone' && (
                <>
                    <StateAccordion
                        title="Номера телефонии"
                        render={(props) => <PointList {...props} />}
                        withoutPadding
                    />
                    <StateAccordion title="Исходящие звонки" render={(props) => <OutcomingList {...props} />} />
                    <StateAccordion title="Входящие звонки" render={(props) => <IncomingList {...props} />} />
                </>
            )}
            <StateAccordion title="История тарифов" render={(props) => <TariffList {...props} />} withoutPadding />
            {enableSberbank && (
                <StateAccordion
                    title="История платежей (Сбербанк)"
                    withoutPadding
                    render={({ open }) => <SberbankHistory open={open} />}
                />
            )}
            {enablePayOnline && (
                <StateAccordion
                    title="История платежей (PayOnline)"
                    withoutPadding
                    render={({ open }) => <PayonlineHistory open={open} />}
                />
            )}
        </Content>
    );
};

const Component = pipe(Advanced, withTitle(LOCATION_CAPTION.ADVANCED));
export { Component as Advanced };
